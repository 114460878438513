export default function Semiconductor() {
  return (
    <section className="bg-[#04092f] text-white p-20">
      <div className="mx-auto flex flex-col lg:flex-row justify-between gap-8">
        {/* Section 1 */}
        <div className="flex-1">
          <p className="text-4xl font-bold">Integrated Semiconductor Project</p>

          <div className="mt-7">
            <p className="text-2xl">Raw Silicon to semiconductor chips</p>
            <ul className="list-disc list-inside flex flex-col gap-1 mt-2 text-lg">
              <li>Silicon Ingots from raw silicon</li>
              <li>Semiconductor Fabrication</li>
              <li>Assembly, Testing Marking and Packing</li>
            </ul>
          </div>
        </div>

        {/* Section 2 */}
        <div className="flex-1">
          <p className="text-4xl font-bold">Silicon Extraction:</p>

          <div className="mt-7">
            <ul className="list-disc list-inside flex flex-col gap-1 mt-2 text-lg">
              <li>
                Refined from quartzite to electronic-grade silicon via siemens
                process.
              </li>
              <li>
                Single Crystal Ingots grown with Czochralski Process, sliced
                into wafers.
              </li>
            </ul>
          </div>
        </div>

        {/* Section 3 */}
        <div className="flex-1">
          <p className="text-4xl font-bold">Manufacturing Process:</p>

          <div className="mt-7">
            <ul className="list-disc list-inside flex flex-col gap-1 mt-2 text-lg">
              <li>
                Photolithography: Wafers coated with photoresist, exposed, and
                developed to reveal patterns.
              </li>
              <li>
                Etching and Doping: patterns etched and doped to alter
                electrical properties.
              </li>
              <li>
                Metallization: Metal layers added for electrical connections,
                forming circuits.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
