import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const UpperHeader = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const headerContent = (
    <>
      <span className="text-lg lg:text-xl font-medium">
        Call Us: +91 9150016223
      </span>
      <span className="text-lg lg:text-xl font-medium">
        Email: info@vitpark.com
      </span>
      <Link to="/privacy-policy" className="text-lg lg:text-xl font-medium">
        Privacy Policy
      </Link>
      <Link to="/data-deletion-policy" className="text-lg lg:text-xl font-medium">
        Data Deletion Policy
      </Link>
    </>
  );

  return (
    <>
      {isMobile ? (
        <div className="relative">
          <button 
            onClick={toggleSidebar} 
            className="fixed top-4 left-4 z-50 text-white"
          >
            ☰
          </button>
          <div 
            className={`fixed top-0 left-0 h-full w-64 bg-[#282828] text-white transition-transform duration-300 ease-in-out transform ${
              isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
            }`}
          >
            <div className="flex flex-col items-start p-4 space-y-4">
              {headerContent}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-between items-center w-full h-12 bg-[#282828] text-white font-sans px-4 lg:px-6">
          {headerContent}
        </div>
      )}
    </>
  );
};

export default UpperHeader;