import React from "react";
import patner from "./images/patner.png";
import product from "./images/cholaLogo2.png";

const Footer = () => {
  return (
    <footer className="bg-gray-950 text-white py-6">
      <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 py-10">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* First Column */}
          <div>
            <div className="text-2xl mb-2 font-bold">OUR EXPERTISE</div>
            <ul className="space-y-2 text-[17px]">
              <li>
                <div className="text-[17px] hover:text-gray-300">
                  Payment Gateways
                </div>
              </li>
              <li>
                <div className="text-[17px] hover:text-gray-300">
                  Last Mile Connectivity
                </div>
              </li>
              <li>
                <div className="text-[17px] hover:text-gray-300">IT Park</div>
              </li>
              <li>
                <div className="text-[17px] hover:text-gray-300">
                  E-business
                </div>
              </li>
              <li>
                <div className="text-[17px] hover:text-gray-300">Mobility</div>
              </li>
              <li>
                <div className="text-[17px] hover:text-gray-300">
                  Semiconductor Solutions
                </div>
              </li>
            </ul>
          </div>
          {/* Second Column */}
          <div>
            <div className="text-2xl mb-2 font-bold">Get In Touch</div>
            <ul className="space-y-4 ">
              <li>
                <div className="text-[17px] hover:text-gray-300">
                  Appavoo Apartments, 1/5, Venus Colony 2nd St, Venus Colony,
                  Alwarpet, Chennai, Tamil Nadu 600018
                </div>
              </li>
              <li>
                <div className="text-[17px] hover:text-gray-300">
                  Ph: +91 9150016223
                </div>
              </li>
              <li>
                <div className="text-[17px] hover:text-gray-300">
                  Mail: info@vitpark.com
                </div>
              </li>
            </ul>
          </div>
          {/* Third Column */}
          <div>
            <div className="bg-white p-4 w-full h-[230px] rounded">
              <div className="flex justify-between items-center mb-4">
                {/* Our Partner */}
                <div className="lg:mt-4">
                  <a href="https://biowms.com/">
                    <h4 className="text-[17px] font-bold mb-2 text-gray-900">
                      Our Partner
                    </h4>
                    <img src={patner} alt="BIOWMS" className="w-20 h-20 mb-2" />
                    <h1 className="text-gray-900">BIOWMS</h1>
                  </a>
                </div>
                {/* Our Product */}
                <div className="lg:mt-4">
                  <a href="https://kind-ocean-0841d5800.5.azurestaticapps.net/">
                    <h4 className="text-[17px] font-bold mb-2 text-gray-900">
                      Our Product
                    </h4>
                    <img
                      src={product}
                      alt="Chola Chariots"
                      className="w-30 h-20 mb-2"
                    />
                    <h1 className="text-gray-900 text-[17px]">
                      Chola Chariots
                    </h1>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* All rights reserved */}
      <div
        className="max-w-8xl
      mx-auto px-4 sm:px-6 lg:px-10 text-center mt-4"
      >
        <p className="text-[19px]">
          &copy; 2024 VIT Park. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
