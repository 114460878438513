import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AboutUs from "./Components/AboutUs";
import Home from "./Components/Home";
import Navbar from "./Components/Navbar";
import PageNotFound from "./Components/PageNotFound";
import UpperHeader from "./Components/UpperHeader";
import Footer from "./Components/Footer";
import PrivacyPolicy from "./Components/PrivacyPolicy"; 
import DataDeletionPolicy from "./Components/DataDeletionPolicy"; 

function App() {
  return (
    <div className="overflow-hidden">
      <Router>
        <UpperHeader />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/data-deletion-policy" element={<DataDeletionPolicy />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;